import axios from 'axios'
export default {
    getInfoData (params) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.VUE_APP_BASE_URL}/api/teleconsult-room`, {
                headers: {
                  "X-TenantId": params.tenantId,
                },
                params,
              }).then(_respData => resolve(_respData.data))
              .catch(err => reject(new Error(err)))
        })
    },
    getNumberContact (tenantId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/api/contact-center`, {
          headers: {
            "X-TenantId": tenantId,
          },
        }).then(_resp => {
          resolve(_resp.data)
        }).catch(err => reject(new Error(err)))
      })
    },
    getConfigMessage(tenantId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/api/teleconsult-config`, {
          headers: {
            "X-TenantId": tenantId,
          },
        }).then(_resp => {
          resolve(_resp.data)
        }).catch(err => reject(new Error(err)))
      })
    },
    changeDay(day) {
      if (day === 'MONDAY') {
        return 'Senin'
      } else if (day === 'TUESDAY') {
        return 'Selasa'
      } else if (day === 'WEDNESDAY') {
        return 'Rabu'
      } else if (day === 'THURSDAY') {
        return 'Kamis'
      } else if (day === 'FRIDAY') {
        return 'Jumat'
      } else if (day === 'SATURDAY') {
        return 'Sabtu'
      } else if (day === 'SUNDAY') {
        return 'Minggu'
      }
    },
    formatDate (date) {
      let data = date.split('-')
      if (data.length < 2) return ''
      const day = data[2]
      const month = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
      const year = data[0]
      return `${day} ${month[data[1] - 1]} ${year}`
    }
}