import Vue from "vue";
import VueRouter from "vue-router";
import WaitingRoom from "../views/WaitingRoom.vue";
import store from "../store";
import Repository from "@/helpers/repository";

Vue.use(VueRouter);

const getDataAPI = (params) => {
  return new Promise(async (resolve) => {
    await Promise.allSettled([
      Repository.getInfoData(params),
      Repository.getNumberContact(params.tenantId),
    ]).then(async ([dataUser, whatsapp]) => {
      resolve([dataUser, whatsapp]);
    });
  });
};

function checkIsValidFromVuex (to, from, next) {
  if (store.state.isAccess) {
    next()
   } else {
    next({name: 'waiting', query: to.query})
   }
}


const routes = [
  {
    path: "/telmed",
    name: "Telekonsultasi",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Telekonsultasi.vue"),
    beforeEnter: (to, from, next) => {
        checkIsValidFromVuex(to, from , next)
      },
  },
  {
    path: "/404",
    name: "notFound",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotFound.vue"),
  },
  {
    path: "/checkPermission",
    name: "checkPermission",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CheckPermission.vue"),
      beforeEnter: async (to, from, next) => {
        const {linkId, password} = to.query
        let fromLocal = sessionStorage.queryParam ?? {}
        let data = []
        try {
          fromLocal = JSON.parse(fromLocal)
        } catch {

        }
        if (linkId && password) {
          data = await getDataAPI(to.query)
        } else {
          data = await getDataAPI(fromLocal)
        }
        store.commit('SET_DATA_TELEKONSUL', data)
        store.commit('SET_CAN_ACCESS', true)
        next()
      }
  },
  {
    path: "/endCall",
    name: "endCall",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EndCall.vue"),
    beforeEnter: (to, from, next) => {
        checkIsValidFromVuex(to, from , next)
      },
  },
  {
    path: "/waitingRoom",
    name: "waitingRoom",
    component: WaitingRoom,
    beforeEnter: (to, from, next) => {
      checkIsValidFromVuex(to, from , next)
    },
  },
  {
    path: "/waiting",
    redirect: "/checkPermission",
    name: "waiting",
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () =>
      import(
        /* webpackChunkName: "terms-and-conditions" */ "../views/TncPage.vue"
      ),
  },
  {
    path: "*",
    name: "home",
    redirect: { name: "notFound" },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
