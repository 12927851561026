<template>
 <div class="main-container">
  <img src="@/assets/image/caredokter.svg" class="img-care-dokter" />
  <div class="flex-box-telemedicine">
    <div class="telekonsul-mobile c-primary">
      Telekonsultasi
    </div>
    <div class="container-dekstop text-center name-waiting">
      <span class="fs-16 fw-700 c-gray-A">{{
        $store.state.roleDokter ? "Pasien Anda" : "Join sebagai"
      }}</span>
      <p class="fs-24 fw-700 c-primary">
        {{ keterangan.reservasi ? keterangan.reservasi.patient.patientName : '' }}
        <!-- <template v-if="$store.state.roleDokter">
          (Pasien)
        </template> -->
      </p>
    </div>
    <div class="container-dekstop text-center box-status-waiting" >
      <div class="bg-box-detail" v-if="loading || $store.state.roleDokter">
        <template v-if="loading">
        <p class="mb-0 c-gray-A fs-16 fw-700">Status</p>
        <p class="mb-0 c-primary fs-20 fw-700">
          Menunggu {{ $store.state.roleDokter ? "Pasien" : "Dokter" }} untuk
          join ke ruangan
        </p>
        </template>
        <div :class="loading ? 'link-data': ''" v-if="$store.state.roleDokter">
          <p class="c-gray-A fs-16 fw-700">
            Pasien belum masuk? Share link ke pasien
          </p>
          <div
            class="mx-auto box-copy-link"
          >
            <span
              class="d-inline-block text-truncate align-middle"
            >
              {{ linkPasien }}
            </span>
             <button
                  type="button"
                  class="btn btn-default btn-copy js-tooltip js-copy"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Copy to clipboard"
                  data-original-title="copy"
                  @click="copyCommand(linkPasien)"
                >
                  <span class="fs-14 fw-500 c-primary"><i class="fa fa-regular fa-clone fw-500"></i> Copy</span>
                </button>
          </div>
        </div>
      </div>
      <button
        class="btn-primary-telekonsul btn-block fs-20 fw-700 border-radius-24"
        @click="initSocket()"
        :disabled="loading"
      >
        <!-- <template>
        </template> -->
        <spinner-icon class="spinner-spin" v-if="loading"/>
        <span>{{loading ? 'Mohon Tunggu': 'Join Telekonsul'}}</span>
      </button>
    </div>
    <div class=" footer-waiting mx-auto">
      <p class="c-gray-A fs-16 fw-700 mb-16">Butuh Bantuan?</p>

      <div class="mx-auto margin-bottom-32 d-grid-mobile">
        <button
          class="btn-primary-telekonsul fs-14 fw-700 mx-2 border-radius-8"
          @click="sendWA"
        >
          <i class="fab fa-whatsapp"></i> WA Contact Center
        </button>
        <button
          class="btn-secondary-telekonsul fs-14 fw-700 mx-2 border-radius-8"
          @click="goToTerm"
        >
          Syarat dan Ketentuan
        </button>
      </div>

      <div class="box-status-data border-radius-16 text-center">
         <div class="d-inline-block mr-1 status-circle" :class="connection.cssClass"></div>
         <div class="d-inline-block">{{ connection.text }}</div>
      </div>
    </div>
    <div class="box-info-telekonsul">
      <p class="c-primary fw-700">Telekonsultasi</p>
      <p class="c-primary fw-700">{{ keterangan.reservasi ? keterangan.reservasi.jadwal.dokter.nama: '' }}</p>
      <p class="c-primary fw-700">
        {{
          keterangan.reservasi && keterangan.reservasi.jadwal.dokter.tujuanRujukan
            ? keterangan.reservasi.jadwal.dokter.tujuanRujukan.namaIndo
            : "-"
        }}
      </p>
      <p class="c-primary fs-16 fw-600">
        {{ hari }}
        {{ tanggalKunjungan }}
      </p>
      <p class="c-primary fs-16 fw-500">
        {{keterangan.reservasi ?  keterangan.reservasi.jamMulai : '' }} -
        {{ keterangan.reservasi ? keterangan.reservasi.jamSelesai : '' }}
      </p>
    </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import ModalPassword from "../components/ModalPassword";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import FlipCountdown from "vue2-flip-countdown";
import { JSEncrypt } from "jsencrypt";
import SpinnerIcon from "../components/icons/SpinnerIcon.vue";
import sendWhatsapp from '@/helpers/sendText'
import Repository from '@/helpers/repository'

export default {
  name: "WaitingRoom",
  data() {
    return {
      intervalConnection: "",
      connection: {
        text: "",
        cssClass: "",
      },
      isPass: false,
      params: {},
      keterangan: {},
      roleDokter: false,
      stompClient: null,
      userReady: false,
      inputPassword: null,
      waiting: false,
      passIncluded: false,
      inputPassword: null,
      early: false,
      // timeLeft: "",
      sesiExpired: false,
      readyToRoom: false,
      askReady: false,
      loading: false,
      timeout: 5000,
      linkPasien: "",
      isFirstTime: true,
      status: [
        { text: "Idle",
          cssClass: "status-idle" },
        {
          text: "Menunggu koneksi tersambung",
          cssClass: "status-wsconnecting blink-me",
        },
        {
          text: "Sambungan gagal, menyambungkan ulang...",
          cssClass: "status-wsconnfailed",
        },
        {
          text: "Koneksi tersambung, meminta sesi...",
          cssClass: "status-wsconnsuccess",
        },
        {
          text: "Menunggu partisipan",
          cssClass: "status-waitingready blink-me",
        },
        {
          text: "Partisipan telah hadir, menyiapkan room...",
          cssClass: "status-allready",
        },
        { text: "Kode tidak valid",
          cssClass: "status-wsconnfailed"
        },
      ],
    };
  },
  computed: {
    hari () {
      let dataHari = this.keterangan.reservasi ? this.keterangan.reservasi.jadwal.hari : 'MONDAY'
      return Repository.changeDay(dataHari)
    },
    tanggalKunjungan () {
      let dataTanggal = this.keterangan.reservasi ? this.keterangan.reservasi.tanggalKunjungan : ''
      return Repository.formatDate(dataTanggal)
    }
  },
  components: {
    ModalPassword,
    FlipCountdown,
    SpinnerIcon,
  },
  methods: {
     copyCommand(text) {
      const _evt =  $(".js-copy")
      var copyTest = document.queryCommandSupported("copy");
            var elOriginalText = _evt.attr("data-original-title");
           
            if (copyTest === true) {
              var copyTextArea = document.createElement("textarea");
              copyTextArea.value = text;
              document.body.appendChild(copyTextArea);
              copyTextArea.select();
              try {
                var successful = document.execCommand("copy");
                var msg = successful ? "Copied!" : "Whoops, not copied!";
                _evt.attr("data-original-title", msg).tooltip("show");
              } catch (err) {
                console.log("Oops, unable to copy");
              }
              document.body.removeChild(copyTextArea);
              _evt.attr("data-original-title", elOriginalText);
            } else {
              // Fallback if browser doesn't support .execCommand('copy')
              window.prompt("Copy to clipboard: Ctrl+C or Command+C, Enter", text);
            }
    },
    async sendWA () {
      const isDokter = this.$store.state.roleDokter
      const getConfig = await Repository.getConfigMessage(this.keterangan.reservasi.tenantId)
      let message = ''
      if(isDokter) {
        message = getConfig.message.dokter
      } else {
        message = getConfig.message.pasien
      }
      const urlWA = sendWhatsapp.sendWhatsapp(this.keterangan, message)
      setTimeout(() => {
          window.open(`${urlWA}`, '_blank');
      }, 100)
    },
    goToTerm () {
      this.$router.push({name: 'terms-and-conditions'})
    },
    parseTime(time) {
      let c = time.split(":");
      // console.log(parseInt(c[0]) * 60 + rpaseInt(c[1]));
      return parseInt(c[0]) * 60 + parseInt(c[1]);
    },
    verifyPass() {
      // rsa key here
      // this.params.password = inputPassword;
      // New JSEncrypt object
      let encryptor = new JSEncrypt();
      // Setting public key
      encryptor.setPublicKey(
        "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAK+i+DCC0/gHMonvl0SfsGwHJnsXJvDIY3xeKdR1VHTxr4lFGt/eMq6sQ40cSdyObW/c3fjnzkZIT/eo8Xy0+jMCAwEAAQ=="
      );
      // Encrypted data
      this.params.password = encryptor.encrypt(this.inputPassword);

      if (this.inputPassword) {
        this.passIncluded = true;
        this.getToken();
      }
    },
    getPrinterRepository() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/api/back-office-environment`, {
          headers: {
            "X-TenantId": this.params.tenantId,
          },
        })
        .then((data) => {})
        .catch((err) => {
          console.log("getData", err);
        });
    },
    async failedSession() {
      this.connection = this.status[5];
      // this.$vToastify.info("refreshing token");
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/telemedicine/api/complete-task/failed-create-opentok-session`,
          "",
          {
            headers: {
              "X-TenantId": this.params.tenantId,
            },
            params: {
              assigneeId: this.params.linkId,
            },
          }
        )
        .then(() => {
          this.connection = this.status[5];
          // this.getToken();
          // this.initSocket();
        })
        .catch(() => {
          this.connection = this.status[6];
        });
    },
    getSpesialis(items) {
      const index = items.findIndex((item) => item.default);
      if (index > -1) {
        return items[index].spesialisasi;
      }
      return {
        nama: "-",
      };
    },
    async getToken() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/api/teleconsult-room`, {
          headers: {
            "X-TenantId": this.params.tenantId,
          },
          params: this.params,
        })
        .then(async _resp => {
          let data = _resp.data
          const contact = await Repository.getNumberContact(data.reservasi.tenantId)
          return {
            ...data,
            ...contact
          }
        })
        .then(data => {
          if (!this.isFirstTime) {
            this.connection = this.status[4];
          }
          const mapperDokter = data.reservasi.jadwal.dokter;
          data.reservasi.jadwal.dokter.tujuanRujukan = this.getSpesialis(
            mapperDokter.dokterSpesialisasis
          );
          this.keterangan = data;
          this.$store.commit("SET_TELE_DATA", this.keterangan);

          // handle disini utk session null
          // pengecekan sudah selesai apa belum
          if (
            !data.sessionId &&
            data.doctorReadyAt &&
            data.patientReadyAt &&
            data.doctorLeaveAt &&
            data.patientLeaveAt
          ) {
            // telekonsul selesai
            this.$router.push("/endCall");
          } else if (
            (data.accessToken || data.guestAccessToken) &&
            !data.sessionId
          ) {
            this.$router.push("/endCall");
          } else if (
            data.doctorReadyAt &&
            data.patientReadyAt &&
            !data.sessionId &&
            !this.isFirstTime
          ) {
            // ketika pasien siap & dokter siap tetapi sesion id tidak ada
            this.failedSession();
          } else if (!data.sessionId) {
            // telekonsul baru
            this.askReady = true;
          } else {
            // sessionId = true
            clearInterval(this.intervalConnection);
            this.askReady = false;
            this.loading = true;
            let telfo = JSON.parse(localStorage.getItem("telmedinfo"));
            if (!this.$store.state.accessToken) {
              this.$store.commit("SET_TELE_DATA", telfo);
            }
            
            setTimeout(() => {
              this.$router.push("/telmed");
            }, 3000);
          }
        })
        .catch(() => {
          this.connection = this.status[6];
        });
      // .finally(() => {
      // this.timeLeft = `${this.keterangan.reservasi.tanggalKunjungan} ${this.keterangan.reservasi.jamMulai}:00`;
      // });
    },
    initSocket() {
      this.connection = this.status[1];
      this.loading = true;
      this.isFirstTime = false;
      // let str = Object.entries(this.params)
      //   .map(([key, val]) => key + "=" + val + "")
      //   .join("&");
      let urlParams = new URL(
        `${process.env.VUE_APP_BASE_URL}/websocket/teleconsult/room`
      );
      for (let i in this.params) {
        if (this.params.hasOwnProperty(i)) {
          urlParams.searchParams.append(i, this.params[i]);
        }
      }

      // let base = process.env.VUE_APP_MIX_WEB_SOCKET;
      var socket = new SockJS(urlParams);
      this.stompClient = Stomp.over(socket);
      const that = this;
      this.stompClient.debug = () => {};
      this.stompClient.connect(
        {},
        (frame) => {
          this.connection = this.status[3];
          this.subsSocket();
          console.log("frameeee -->", frame);
        },
        (error) => {
          this.connection = this.status[2];
          //
          // console.log(error)
          setTimeout(() => {
            if (!that.stompClient.connected) {
              that.stompClient.disconnect();
              // this.readyWaiting()
              that.initSocket();
              // that.$vToastify.info("Mencoba menyambungkan kembali");
            } else {
              console.log("connect , dan menunggu update websocket");
            }
          }, 5000);
          console.log("Reconnect WebStomp", error);
        }
      );
    },
    objectDecode(item) {
      let newObject = {};
      const amp = "amp;";
      Object.keys(item).forEach(function(key) {
        const value = item[key];
        if (key.includes(amp)) {
          key.replace(amp, "");
        }

        newObject[key] = decodeURIComponent(value);
      });
      return newObject;
    },
    subsSocket() {
      // ketika gadapet2 socket
      this.intervalConnection = setInterval(() => {
        this.getToken();
      }, this.timeout);
      this.stompClient.subscribe(
        `/topic/teleconsult/room/${encodeURIComponent(this.params.linkId)}`,
        (tick) => {
          let msg = JSON.parse(tick.body);
          if (tick) {
            this.getToken();
          }
        },
        (Headers = {
          password: `${encodeURIComponent(this.params.password)}`,
        })
      );
      // }
    },
  },
  mounted() {
    this.connection = this.status[0];
    var ua = navigator.userAgent;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    var x = document.getElementsByTagName("BODY")[0];
    if (isSafari) {
      x.classList.add("b-mobile");
      if (/iPhone|iPod/i.test(ua)) {
        x.classList.add("iphone");
      }
    } else if (
      /Android|webOS|Tablet|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(
        ua
      )
    ) {
      x.classList.add("b-mobile");
    } else /Chrome/i.test(ua);

    localStorage.setItem("linkParams", this.$route.fullPath);
    this.params = this.$route.query;
    if(this.params.linkId) {
        sessionStorage.setItem('queryParam', JSON.stringify(this.$route.query))
      } else {
        this.params = sessionStorage.queryParam ? JSON.parse(sessionStorage.queryParam) : {} 
    }
     let paspas = this.params.password;
      if (!paspas) {
        paspas = this.params["amp;password"];
      }
    this.params = this.objectDecode(this.params);
    this.linkPasien = `${window.location.origin}/waiting?`;
    this.linkPasien += Object.entries(this.params)
      .map(([key, val]) =>
        key !== "key" ? key + "=" + encodeURIComponent(val) + "" : ""
      )
      .join("&");
    this.$store.commit("SET_LINK_PASIEN", this.linkPasien);
    this.timeout = parseInt(process.env.VUE_APP_TIMEOUT);

    if (paspas) {
      this.passIncluded = true;
      this.getToken();
    } else {
      this.$vToastify.info("please input the right one", "Password needed");
    }
    if (this.params.key) {
      this.roleDokter = true;
      this.$store.commit("SET_ROLE_DOKTER", true);
    }
  },
};
</script>
<style>
.sWaiting {
  background-image: url(./../assets/Group_124.svg);
  background-size: cover;
  background-position: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  margin-top: 50px;
}

.btn-msk {
  padding: 1.375rem 1.75rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 3.25rem;
}

.tel-log {
  margin-left: 3rem !important;
}

.ketIndo {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

#ketBottom {
  display: none;
}

.b-mobile .copyPasien h4 {
  font-size: 10px !important;
}

.b-mobile .copyPasien span {
  font-size: 10px !important;
}

.b-mobile .copyPasien div {
  max-block-size: 250px !important;
}

.b-mobile .btn-msk {
  padding: 1rem 1.75rem;
  font-size: 1.2rem;
}

.b-mobile #ketBottom {
  display: block;
}

.b-mobile #ketTop {
  display: none;
}

.b-mobile .sWaiting {
  margin-top: 50px;
}

.b-mobile h4 {
  font-size: 1rem !important;
}

.b-mobile h2 {
  font-size: 1.5rem !important;
}

.b-mobile .tel-log {
  height: 30px !important;
  margin-left: 2rem !important;
  margin-right: 0.5rem !important;
}

.b-mobile .telTitle {
  font-size: 16px !important;
  margin-top: 0.5rem !important;
}

.b-mobile .ketIndo {
  margin-left: 1rem;
  margin-right: 1rem;
}

.flip-card__top[data-v-78efe7f6],
.flip-card__bottom[data-v-78efe7f6],
.flip-card__back-bottom[data-v-78efe7f6],
.flip-card__back[data-v-78efe7f6]::before,
.flip-card__back[data-v-78efe7f6]::after {
  color: #0062cc !important;
  background: #e9ecef !important;
  border-top: solid 1px #ced4da !important;
}

.flip-clock__slot {
  display: none !important;
}

.footer-tnc {
  position: absolute;
  bottom: 30px;
  left: 10px;
}
.absolute-top-0-right-0 {
  display: block;
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
}

</style>
