<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.75 2.25C14.75 3.49266 13.7422 4.5 12.5 4.5C11.2578 4.5 10.25 3.49266 10.25 2.25C10.25 1.00734 11.2578 0 12.5 0C13.7422 0 14.75 1.00734 14.75 2.25ZM14.75 21.75C14.75 22.9922 13.7422 24 12.5 24C11.2578 24 10.25 22.9922 10.25 21.75C10.25 20.5078 11.2578 19.5 12.5 19.5C13.7422 19.5 14.75 20.5078 14.75 21.75ZM0.5 12C0.5 10.7578 1.50734 9.75 2.75 9.75C3.99266 9.75 5 10.7578 5 12C5 13.2422 3.99266 14.25 2.75 14.25C1.50734 14.25 0.5 13.2422 0.5 12ZM24.5 12C24.5 13.2422 23.4922 14.25 22.25 14.25C21.0078 14.25 20 13.2422 20 12C20 10.7578 21.0078 9.75 22.25 9.75C23.4922 9.75 24.5 10.7578 24.5 12ZM4.01469 20.4844C3.13578 19.6078 3.13578 18.1828 4.01469 17.3016C4.89359 16.425 6.31719 16.425 7.19844 17.3016C8.075 18.1828 8.075 19.6078 7.19844 20.4844C6.31719 21.3656 4.89359 21.3656 4.01469 20.4844ZM7.19844 6.69844C6.31719 7.575 4.89359 7.575 4.01469 6.69844C3.13625 5.81719 3.13625 4.39359 4.01469 3.51469C4.89359 2.63578 6.31719 2.63578 7.19844 3.51469C8.075 4.39359 8.075 5.81719 7.19844 6.69844ZM17.8016 17.3016C18.6828 16.425 20.1078 16.425 20.9844 17.3016C21.8656 18.1828 21.8656 19.6078 20.9844 20.4844C20.1078 21.3656 18.6828 21.3656 17.8016 20.4844C16.925 19.6078 16.925 18.1828 17.8016 17.3016Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
    props: {
        width:{type: Number, default: 25},
        height: {type: Number, default: 24}
    }
};
</script>

<style></style>
