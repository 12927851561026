const EnumWhatsapp = {
    TIME: 'DT-1',
    DAY: 'DAY-',
    LOWER_CASE: 'LC-1',
    UPPER_CASE: 'UC-1',
    CAPITALIZE_CASE: 'CC-1',
    CAPITALIEZE_CASE_ALL: 'CC-2'
}
import repository from "./repository"
export default {
    sendWhatsapp(data, message) {
        const listPerubahan = this.getVarData(message)
        listPerubahan.forEach(element => {
            message = message.replace(element, this.getInObjectKey(element, data))
        })
        const numberWhatsapp = data.whatsApp ? data.whatsApp : process.env.VUE_APP_NUMBER_WHATSAPP
        const url = `https://wa.me/${numberWhatsapp}`
        let urlText = `${url}?text=${encodeURIComponent(message)}`
        return urlText
    },
    getVarData(textData) {
        let data = textData ?? ''
        const arrayMatch = data.match(/\{[a-zA-Z0-9\.\-]+\}/g)
        return arrayMatch ? arrayMatch : [];
      },
      getInObjectKey(keyOri, data) {
        let key = keyOri.replace('{', '').replace('}', '')
        const arrayKey = key.split('.')
        var typeStatus = ''
        const dataReduce = arrayKey.reduce((prev, next) => {
          let nextKey = next
          if (nextKey.indexOf(EnumWhatsapp.TIME) ==0) {
            typeStatus = EnumWhatsapp.TIME
            nextKey = nextKey.replace(EnumWhatsapp.TIME, '')
          } else if (nextKey.indexOf(EnumWhatsapp.LOWER_CASE) == 0) {
            typeStatus = EnumWhatsapp.LOWER_CASE
            nextKey = nextKey.replace(EnumWhatsapp.LOWER_CASE, '')
          } else if (nextKey.indexOf(EnumWhatsapp.DAY) == 0) {
            typeStatus = EnumWhatsapp.DAY
            nextKey = nextKey.replace(EnumWhatsapp.DAY, '')
          } else if (nextKey.indexOf(EnumWhatsapp.UPPER_CASE) == 0) {
            typeStatus = EnumWhatsapp.UPPER_CASE
            nextKey = nextKey.replace(EnumWhatsapp.UPPER_CASE, '')
          } else if (nextKey.indexOf(EnumWhatsapp.CAPITALIZE_CASE) == 0) {
            typeStatus = EnumWhatsapp.CAPITALIZE_CASE
            nextKey = nextKey.replace(EnumWhatsapp.CAPITALIZE_CASE, '')
          } else if (nextKey.indexOf(EnumWhatsapp.CAPITALIEZE_CASE_ALL) == 0) {
            typeStatus = EnumWhatsapp.CAPITALIEZE_CASE_ALL
            nextKey = nextKey.replace(EnumWhatsapp.CAPITALIEZE_CASE_ALL, '')
          } 
          prev = prev[nextKey]
          
          if(prev) return prev
          return {}
        }, data)
        if (typeof dataReduce == 'object') {
          return keyOri
        }
        if (typeStatus) return this.mappingSource(dataReduce, typeStatus)
        return dataReduce
      },
      mappingSource(data, type) {
        if (type == EnumWhatsapp.TIME) {
          return repository.formatDate(data)
        } else if (type == EnumWhatsapp.DAY) {
           return repository.changeDay(data)
        } else if (type == EnumWhatsapp.LOWER_CASE) {
          return data.toLowerCase()
        } else if (type == EnumWhatsapp.UPPER_CASE) {
          return data.toUpperCase()
        }  else if (type == EnumWhatsapp.CAPITALIZE_CASE) {
          const lower = data.toLowerCase()
          return data.charAt(0).toUpperCase() + lower.slice(1)
        } else if (type == EnumWhatsapp.CAPITALIEZE_CASE_ALL) {
              return data
                .toLowerCase()
                .split(' ')
                .map(element => element.charAt(0).toUpperCase() + element.slice(1))
                .join(' ');
        }
      }
}